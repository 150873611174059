var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.results)?_c('div',[_c('b-row',[_c('b-col',[_c('h3',[_vm._v(_vm._s(_vm.$tc("result.result", 2)))])]),(_vm.$store.state.user.is_authenticated)?_c('b-col',[(_vm.editPermission && _vm.$store.state.editMode)?_c('b-button',{staticClass:"btn-orange float-right",attrs:{"to":{
          name: 'result-import',
          params: { competition_id: _vm.$route.params.competition_id }
        },"variant":"light"}},[_vm._v(_vm._s(_vm.$tc("result.import", 2))+" ")]):_vm._e(),(
          _vm.$store.state.editMode &&
          (_vm.$store.state.user.is_staff ||
            (_vm.competition.organization in _vm.$store.state.user.manager &&
              !_vm.competition.locked))
        )?_c('b-button',{staticClass:"space-right float-right",attrs:{"variant":"outline-success"},on:{"click":function($event){return _vm.approveAll()}}},[_vm._v(" "+_vm._s(_vm.$t("result.approve_all"))+" ")]):_vm._e(),_c('b-button',{staticClass:"space-right float-right",attrs:{"variant":"outline-info"},on:{"click":function($event){return _vm.getMediaResults()}}},[_vm._v(" "+_vm._s(_vm.$t("result.media_results"))+" ")])],1):_vm._e()],1),_c('b-row',[_c('b-col',[(_vm.errors.main)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('ul',_vm._l((_vm.errors.main),function(e){return _c('li',{key:e},[_vm._v(_vm._s(e))])}),0)]):_vm._e()],1)],1),_c('b-row',[_c('b-col',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadingResults),expression:"loadingResults"}]},[_c('b-spinner',{attrs:{"label":"Loading..."}})],1),_vm._l((_vm.results),function(category){return _c('div',{key:category.id},[_c('h4',{staticClass:"bg-sal-orange"},[_vm._v(" "+_vm._s(_vm._f("splitFilter")(category[0].ordercat,"("))+" ")]),_vm._l((_vm.resultBlocks),function(block,index){return _c('div',{key:index},[(category[0].max_rounds >= 1)?_c('h5',[(block['label'] === 'final')?_c('div',[_vm._v(" "+_vm._s(_vm.$tc("result.final", 2))+" ")]):(block['label'] === 'preliminary')?_c('div',[_vm._v(" "+_vm._s(_vm.$t("result.preliminary"))+" ")]):_c('div',[_vm._v(_vm._s(block["label"]))])]):_vm._e(),(
              block['name'] === 'nolimit' ||
              _vm.getMaxRounds(category, block['name']) >= 1
            )?_c('b-table',{attrs:{"items":_vm.limitResults(category, block),"fields":_vm.filterResultFields(index + 1, _vm.limitResults(category, block)),"sort-by":_vm.sortKey(block['label']),"sort-null-last":"","responsive":"sm","hover":"","small":""},on:{"row-clicked":_vm.linkTo,"row-middle-clicked":_vm.linkToNewTab},scopedSlots:_vm._u([_vm._l((_vm.resultColsExtra[index + 1][1]),function(field){return {key:field.slot,fn:function(data){return _vm._l((_vm.resultColsExtra[index + 1][0][field.col]),function(row){return _c('div',{key:row},[_vm._v(" "+_vm._s(_vm._f("partialValue")(data.item.partial,_vm.getPartialField(index + 1, row, field.col)))+" ")])})}}}),{key:"cell(showcat)",fn:function(data){return [_vm._v("("+_vm._s(data.item.showcat)+")")]}},{key:"cell(athlete)",fn:function(data){return [(data.item.athlete)?_c('div',[_vm._v(" "+_vm._s(data.item.first_name)+" "+_vm._s(data.item.last_name)+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm.$t("team"))+": "+_vm._s(data.item.last_name)+" "),_c('ul',_vm._l((data.item.team_members),function(member){return _c('li',{key:member.id},[_c('router-link',{attrs:{"tag":"a","to":{
                        name: 'athlete',
                        params: { athlete_id: member.id }
                      }}},[_vm._v(" "+_vm._s(member.first_name)+" "+_vm._s(member.last_name)+" ")])],1)}),0)])]}},{key:"cell(result)",fn:function(data){return [(data.item.result_code && !data.item.result)?_c('div',[_vm._v(" "+_vm._s(data.item.result_code)+" ")]):(data.item.result_code && data.item.result)?_c('div',[_vm._v(" "+_vm._s(data.item.result_code)+" ("+_vm._s(_vm._f("roundValue")(data.item.result,data.item.decimals))+") ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("roundValue")(data.item.result,data.item.decimals))+" ")])]}},{key:"cell(pos)",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.$t("result.kneeling")))]),_c('div',[_vm._v(_vm._s(_vm.$t("result.prone")))]),_c('div',[_vm._v(_vm._s(_vm.$t("result.standing")))])]},proxy:true},{key:"cell(position)",fn:function(data){return [(data.item.position)?_c('div',[_vm._v(" "+_vm._s(data.item.position % 100000)+" ")]):_vm._e()]}},{key:"cell(empty)",fn:function(){return undefined},proxy:true},{key:"cell(wtype)",fn:function(data){return [_vm._v(" "+_vm._s(_vm._f("splitFilter")(_vm._f("splitFilter")(data.item.category,"(", 1),")"))+" ")]}},{key:"cell(info)",fn:function(data){return [_vm._v(" "+_vm._s(data.item.info)+" "+_vm._s(_vm._f("parseRecords")(data.item.record,true))+" "),(!data.item.approved)?_c('div',[_vm._v(" ("+_vm._s(_vm.$t("result.unconfirmed"))+") ")]):_vm._e()]}},{key:"cell(detail)",fn:function(data){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal",value:(data.item.id.toString()),expression:"data.item.id.toString()"}],attrs:{"size":"sm","variant":"outline-info"}},[_vm._v(" "+_vm._s(_vm.$t("result.details"))+" ")]),_vm._v("  "),(_vm.editPermission && _vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-success","to":{
                  name: 'result-update',
                  params: {
                    result_id: data.item.id
                  }
                }}},[_vm._v(" "+_vm._s(_vm.$t("edit"))+" ")]):_vm._e(),_vm._v("  "),(
                  _vm.$store.state.editMode &&
                  (_vm.$store.state.user.is_staff ||
                    (_vm.competition.organization in _vm.$store.state.user.manager &&
                      !_vm.competition.locked)) &&
                  !data.item.approved
                )?_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.toggleApproval(data)}}},[_vm._v(" "+_vm._s(_vm.$t("result.approve"))+" ")]):_vm._e(),(
                  _vm.$store.state.editMode &&
                  (_vm.$store.state.user.is_staff ||
                    (_vm.competition.organization in _vm.$store.state.user.manager &&
                      !_vm.competition.locked)) &&
                  data.item.approved
                )?_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.toggleApproval(data)}}},[_vm._v(" "+_vm._s(_vm.$t("result.cancel_approval"))+" ")]):_vm._e(),_vm._v("  "),(_vm.$store.state.editMode && _vm.$store.state.user.is_staff)?_c('b-button',{staticClass:"btn-orange btn-sm",attrs:{"to":{
                  name: 'admin',
                  params: {
                    model_name: 'result',
                    object_id: data.item.id
                  }
                },"variant":"light"}},[_vm._v(_vm._s(_vm.$t("admin"))+" ")]):_vm._e()]}}],null,true)}):_vm._e()],1)}),_vm._l((category),function(result){return _c('div',{key:result.id},[_c('CompetitionResultsDetail',{attrs:{"customFields":_vm.customFields,"maxBlock":_vm.maxBlock,"result":result,"resultBlocks":_vm.resultBlocks}})],1)})],2)})],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }