var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',[_c('b-col',[_c('h2',{staticClass:"bg-sal-orange"},[_vm._v(_vm._s(_vm.$t("competition.info")))])])],1),_c('b-row',[_c('b-col',[(_vm.errors.main)?_c('b-alert',{attrs:{"variant":"danger","show":""}},[_c('ul',_vm._l((_vm.errors.main),function(e){return _c('li',{key:e},[_vm._v(_vm._s(e))])}),0)]):_vm._e()],1)],1),(_vm.loadingCompetition)?_c('b-row',[_c('b-col',[_c('b-spinner',{attrs:{"label":"Loading..."}})],1)],1):_vm._e(),_c('b-row',[(_vm.competition.description)?_c('b-col',{attrs:{"cols":"12"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("description")))]),_c('dd',[_vm._v(_vm._s(_vm.competition.description))])])]):_vm._e(),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("date")))]),(_vm.competition.date_start === _vm.competition.date_end)?_c('dd',[_vm._v(" "+_vm._s(_vm.competition.date_start)+" ")]):_c('dd',[_vm._v(" "+_vm._s(_vm.competition.date_start)+" - "+_vm._s(_vm.competition.date_end)+" ")])])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$tc("event.event", 1)))]),(_vm.competition.event)?_c('dd',[_c('router-link',{attrs:{"tag":"a","to":{
              name: 'event',
              params: {
                event_id: _vm.competition.event
              }
            }}},[_vm._v(_vm._s(_vm.competition.event_info.name))])],1):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.type")))]),(_vm.competition.type_info)?_c('dd',[_vm._v(_vm._s(_vm.competition.type_info.name))]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.level")))]),(_vm.competition.level_info)?_c('dd',[_vm._v(" "+_vm._s(_vm.competition.level_info.name)+" ")]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("organizer")))]),(_vm.competition.organization_info)?_c('dd',[_vm._v(" "+_vm._s(_vm.competition.organization_info.name)+" ")]):_vm._e()])]),_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("location")))]),_c('dd',[_vm._v(_vm._s(_vm.competition.location))])])]),(
        _vm.$store.state.user.is_staff ||
        _vm.competition.organization in _vm.$store.state.user.manager
      )?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.status")))]),(_vm.competition.locked)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("competition.locked"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.toggleLockStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.unlock"))+" ")]):_vm._e()],1):_vm._e(),(!_vm.competition.locked)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("competition.unlocked"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.toggleLockStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.lock"))+" ")]):_vm._e()],1):_vm._e()])]):_vm._e(),(
        _vm.$store.state.user.is_staff ||
        _vm.competition.organization in _vm.$store.state.user.manager
      )?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.approved")))]),(_vm.competition.approved)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("yes"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.toggleApprovedStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.cancel_approval"))+" ")]):_vm._e()],1):_vm._e(),(!_vm.competition.approved)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("no"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.toggleApprovedStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.approve"))+" ")]):_vm._e()],1):_vm._e()])]):_vm._e(),(
        _vm.$store.state.user.is_staff ||
        _vm.competition.organization in _vm.$store.state.user.manager
      )?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.visibility")))]),(_vm.competition.public)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("competition.public"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.togglePublicStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.hide"))+" ")]):_vm._e()],1):_vm._e(),(!_vm.competition.public)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("competition.hidden"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.togglePublicStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("competition.publish"))+" ")]):_vm._e()],1):_vm._e()])]):_vm._e(),(_vm.$store.state.user.is_staff)?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("competition.trial")))]),(_vm.competition.trial)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("yes"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.toggleTrialStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("cancel"))+" ")]):_vm._e()],1):_vm._e(),(!_vm.competition.trial)?_c('dd',[_vm._v(" "+_vm._s(_vm.$t("no"))+" "),(_vm.$store.state.editMode)?_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.toggleTrialStatus()}}},[_vm._v(" "+_vm._s(_vm.$t("set"))+" ")]):_vm._e()],1):_vm._e()])]):_vm._e(),(_vm.$store.state.user.is_staff && _vm.competition)?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("change_log")))]),_c('dd',[_c('b-button',{staticClass:"btn-orange btn-sm",attrs:{"to":{
              name: 'changelog',
              params: {
                model_name: 'competition',
                object_id: _vm.competition.id
              }
            },"variant":"light"}},[_vm._v(_vm._s(_vm.$t("change_log"))+" ")])],1)])]):_vm._e(),(
        _vm.$store.state.editMode && _vm.$store.state.user.is_staff && _vm.competition
      )?_c('b-col',{attrs:{"cols":"6","md":"3"}},[_c('dl',[_c('dt',[_vm._v(_vm._s(_vm.$t("admin")))]),_c('dd',[_c('b-button',{staticClass:"btn-orange btn-sm",attrs:{"to":{
              name: 'admin',
              params: {
                model_name: 'competition',
                object_id: _vm.competition.id
              }
            },"variant":"light"}},[_vm._v(_vm._s(_vm.$t("admin"))+" ")])],1)])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }