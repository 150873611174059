var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"competition"},[_c('b-row',[_c('b-col',[_c('h1',[_vm._v(_vm._s(_vm.$tc("competition.competition", 1))+": "+_vm._s(_vm.competition.name))])]),(_vm.editPermission && _vm.$store.state.editMode)?_c('b-col',[_c('b-button',{staticClass:"btn-orange float-right",attrs:{"to":{
          name: 'competition-update',
          params: {
            event_id: _vm.competition.event,
            competition_id: _vm.competition.id
          }
        },"variant":"light"}},[_vm._v(_vm._s(_vm.$tc("competition.edit", 2))+" ")])],1):_vm._e()],1),_c('Competition',{on:{"update:editPermission":function($event){_vm.editPermission = $event},"update:competition":function($event){_vm.competition = $event}}}),_c('Results',{attrs:{"competition":_vm.competition,"editPermission":_vm.editPermission}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }